var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_vm._m(0),_c('section',{staticClass:"section p-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card-body"},[_c('b-tabs',{attrs:{"no-nav-style":""}},[_c('b-tab',{staticClass:"p-3",attrs:{"no-body":""}},[_c('div',{staticClass:"row"},_vm._l((_vm.props.tabs),function(tab,idx){return _c('div',{key:tab.id,staticClass:"col-md-6 col-lg-3",on:{"click":function($event){return _vm.tabSwitch(tab.id)}}},[_c('a',{staticClass:"accounts rounded d-block shadow text-center py-3",class:{
                    active: _vm.selectedTab === tab.id,
                    completed: _vm.selectedTabIndex > idx,
                  },staticStyle:{"padding":"5px"},attrs:{"href":"javascript:;"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"pro-icons h3 text-muted"},[_c('i',{class:tab.icon})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h6',{staticClass:"t-title title text-dark my-0",domProps:{"innerHTML":_vm._s(tab.text === 'Elije el plan' ? 'Elige el plan' : tab.text)}})])])])])}),0),_c('div',{staticClass:"row rounded mt-5"},[_c('div',{staticClass:"col-md-12 rounded"},[_c('div',{staticClass:"card border-0 rounded shadow"},[_c('div',{staticClass:"card-body"},[_c('keep-alive',[_c(_vm.selectedTab,_vm._g({tag:"component",attrs:{"zones":"xxx"}},{
                          next: () => _vm.handleNext(),
                          prev: () => _vm.handlePrev()
                        }))],1)],1)])])])])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-half bg-light d-table w-100",staticStyle:{"padding":"50px 0px 20px 0px"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('div',{staticClass:"page-next-level"},[_c('h4',{staticClass:"title"},[_vm._v(" Para contratar tu Seguro de Hogar, sigue estos pasos ")]),_c('div',{staticClass:"page-next",staticStyle:{"top":"40px"}},[_c('nav',{staticClass:"d-inline-block",attrs:{"aria-label":"breadcrumb"}},[_c('ul',{staticClass:"breadcrumb bg-white rounded shadow mb-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{staticClass:"router-link-active",attrs:{"href":"/"}},[_vm._v("Inicio")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Cotización de Planes")])])])])])])])])])
}]

export { render, staticRenderFns }